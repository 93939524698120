import { Box, Heading, Flex } from '@chakra-ui/react';
import React from 'react';

import ChainIndicators from 'ui/home/indicators/ChainIndicators';
import LatestBlocks from 'ui/home/LatestBlocks';
import Stats from 'ui/home/Stats';
import Transactions from 'ui/home/Transactions';
import SearchBar from 'ui/snippets/searchBar/SearchBar';

const Home = () => {
  return (
    <Box as="main">
      <Box
        w="100%"
        background={ 'url("assets/header.webp")' }
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        borderRadius="24px"
        padding={{ base: '24px', lg: '48px' }}
        minW={{ base: 'unset', lg: '900px' }}
        data-label="hero plate"
      >
        <Flex mb={{ base: 6, lg: 8 }} justifyContent="center" alignItems="center">
          <Heading
            as="h1"
            size={{ base: 'md', lg: 'xl' }}
            lineHeight={{ base: '32px', lg: '50px' }}
            fontWeight={ 600 }
          >
            <span style={{ fontSize: '32px', fontWeight: 700 }}><span style={{ color: 'rgb(253, 145, 9)' }}>Mazze</span>Scan</span>
          </Heading>
        </Flex>
        <SearchBar isHomepage/>
      </Box>
      <Flex direction={{ base: 'column', lg: 'row' }} gap={{ base: 0, lg: 3 }} alignItems="center" justifyContent="center">
        <Stats/>
        <ChainIndicators/>
      </Flex>
      <Flex mt={ 8 } direction={{ base: 'column', lg: 'row' }} columnGap={ 12 } rowGap={ 8 }>
        <Box flexGrow={ 1 }>
          <Transactions/>
        </Box>
        <LatestBlocks/>
      </Flex>
    </Box>
  );
};

export default Home;
