import { Grid } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { route } from 'nextjs-routes';

import config from 'configs/app';
import useApiQuery from 'lib/api/useApiQuery';
import { HOMEPAGE_STATS } from 'stubs/stats';
import IconSvg from 'ui/shared/IconSvg';
import { PriceInfoTooltip } from 'ui/shared/price/PriceInfoTooltip';

import StatsItem from './StatsItem';

const hasGasTracker = config.features.gasTracker.isEnabled;
const hasAvgBlockTime = config.UI.homepage.showAvgBlockTime;
const rollupFeature = config.features.rollup;

const Stats = () => {
  const { data, isPlaceholderData, isError } = useApiQuery('stats', {
    queryOptions: {
      refetchOnMount: false,
      placeholderData: HOMEPAGE_STATS,
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data: dexScreener }: { data: any } = useQuery(
    {
      queryKey: [ 'dexscreener_stats' ],
      queryFn: () => fetch('https://api.dexscreener.com/latest/dex/tokens/0x4A029F7bCf33AcB03547D8fA7be840347973e24e').then((res) => res.json()) });
  if (isError) {
    return null;
  }

  const isLoading = isPlaceholderData;

  let content;

  let itemsCount = 3;
  !hasGasTracker && itemsCount--;
  !hasAvgBlockTime && itemsCount--;

  if (data) {
    !data.gas_prices && itemsCount--;
    data.rootstock_locked_btc && itemsCount++;
    rollupFeature.isEnabled && data.last_output_root_size && itemsCount++;
    const priceInfoTooltip = (
      <PriceInfoTooltip>
        <IconSvg
          isLoading={ isLoading }
          name="info"
          boxSize={ 5 }
          display="block"
          cursor="pointer"
          _hover={{ color: 'link_hovered' }}
          position="absolute"
          top={{ base: 'calc(50% - 12px)', lg: '10px', xl: 'calc(50% - 12px)' }}
          right="10px"
        />
      </PriceInfoTooltip>
    );

    content = (
      <>
        {
          dexScreener !== undefined && (
            <>
              <StatsItem
                icon="token"
                title="Price"
                value={ `$${ Number(dexScreener.pairs[0].priceUsd).toFixed(5) }` }
                tooltip={ priceInfoTooltip }
                isLoading={ isLoading }
                height="115px"
              />
              <StatsItem
                icon="tokens"
                title="Market Cap"
                value={ `$${ (Number(dexScreener.pairs[0].priceUsd) * 2_540_000_000).toLocaleString() }` }
                isLoading={ isLoading }
              />
            </>
          )
        }
        <StatsItem
          icon="block"
          title="Total blocks"
          value={ Number(data.total_blocks).toLocaleString() }
          url={ route({ pathname: '/blocks' }) }
          isLoading={ isLoading }
        />
        { hasAvgBlockTime && (
          <StatsItem
            icon="clock-light"
            title="Average block time"
            value="3s"
            isLoading={ isLoading }
          />
        ) }
        <StatsItem
          icon="transactions"
          title="Total transactions"
          value={ Number(data.total_transactions).toLocaleString() }
          url={ route({ pathname: '/txs' }) }
          isLoading={ isLoading }
        />
        { rollupFeature.isEnabled && data.last_output_root_size && (
          <StatsItem
            icon="txn_batches"
            title="Latest L1 state batch"
            value={ data.last_output_root_size }
            url={ route({ pathname: '/batches' }) }
            isLoading={ isLoading }
          />
        ) }
        <StatsItem
          icon="wallet"
          title="Wallet addresses"
          value={ Number(data.total_addresses).toLocaleString() }
          isLoading={ isLoading }
          height="115px"
        />
      </>
    );
  }

  return (
    <Grid
      gridTemplateColumns={{ lg: `repeat(${ itemsCount }, 1fr)`, base: `repeat(2, 1fr)` }}
      gridTemplateRows={{ lg: 'none', base: undefined }}
      gridGap="10px"
      marginTop="24px"
      marginBottom="-5px"
      width="100%"
    >
      { content }
    </Grid>

  );
};

export default Stats;
