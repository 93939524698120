import { DarkMode, Flex, Link, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

interface Props {
  children: React.ReactNode;
  isOpen?: boolean;
}

const POPOVER_OFFSET: [ number, number ] = [ 0, 10 ];

export const PriceInfoTooltip = ({ children, isOpen }: Props) => {
  const tooltipBg = useColorModeValue('gray.700', 'gray.900');
  return (
    <Popover trigger="hover" isLazy offset={ POPOVER_OFFSET } isOpen={ isOpen }>
      <PopoverTrigger>
        { children }
      </PopoverTrigger>
      <Portal>
        <PopoverContent bgColor={ tooltipBg } w="auto">
          <PopoverBody color="white">
            <DarkMode>
              <Flex flexDir="row" fontSize="xs" lineHeight={ 4 } gap={ 1 }>
                Information provided by<Link href="https://dexscreener.com" target="_blank" color="orange.400">DexScreener</Link>
              </Flex>
            </DarkMode>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
